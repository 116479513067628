/**
 * It just so happened that our user update view, login view and registration
 * view all used Select2, so they get grouped together here even though the
 * rest of this module's extra features only applies to login & registration.
 */

import { Modal } from "bootstrap";

import * as select2 from "../select2";
import * as preloader from "../preloader";
import * as alerts from "../alerts";
import * as modals from "../modals";
import { BaseEntrypoint } from "./base";


class UserManagementEntrypoint extends BaseEntrypoint {
    readonly downloadDataModal = document.getElementById("download-data-modal");
    readonly deleteAccountModal = document.getElementById("delete-account-modal");

    readonly loginForm?: HTMLFormElement;
    readonly registerForm?: HTMLFormElement;

    constructor() {
        super();

        const loginForm = document.getElementById("login-form");
        const registerForm = document.getElementById("register-form");

        if (loginForm instanceof HTMLFormElement) this.loginForm = loginForm;
        if (registerForm instanceof HTMLFormElement) this.registerForm = registerForm;

        select2.initAutocomplete(document);
        if (this.loginForm) this.initForm(this.loginForm, ["username", "password"]);
        if (this.registerForm) this.initForm(this.registerForm, ["email"]);
        if (this.downloadDataModal) this.initDownloadData();
        if (this.deleteAccountModal) this.initDeleteAccount();
    }

    initDeleteAccount() {
        const confirmCheckbox = this.deleteAccountModal?.querySelector("#delete-account-confirm-checkbox");
        const confirmClassesCheckbox = this.deleteAccountModal?.querySelector("#delete-classes-confirm-checkbox");
        const submit = this.deleteAccountModal?.querySelector("#delete-account-submit");

        if (
            confirmCheckbox instanceof HTMLInputElement &&
            confirmClassesCheckbox instanceof HTMLInputElement &&
            (submit instanceof HTMLInputElement || submit instanceof HTMLButtonElement)
        ) {
            confirmCheckbox.addEventListener("change", () => {
                submit.disabled = !confirmCheckbox.checked;
                confirmClassesCheckbox.disabled = !confirmCheckbox.checked;
            });

            this.deleteAccountModal?.addEventListener("show.bs.modal", () => {
                confirmCheckbox.checked = false;
                confirmClassesCheckbox.checked = false;
                confirmClassesCheckbox.disabled = true;
                submit.disabled = true;
            });

        }

        this.deleteAccountModal?.querySelector("#download-data-link")?.addEventListener("click", event => {
            event.preventDefault();
            if (this.downloadDataModal) modals.openNow(this.downloadDataModal);
        });
    }

    initDownloadData() {
        const form = this.downloadDataModal?.querySelector("#download-data-form");

        if (form instanceof HTMLFormElement) {
            const submitButton = form.querySelector("[type=submit");
            form.addEventListener("submit", event => {
                event.preventDefault();
                preloader.show(this.downloadDataModal);

                const formData = new FormData(form);
                let filename: string | undefined;

                fetch(form.action, { method: "POST", body: formData })
                    .then(response => {
                        if (!response.ok) throw new Error(response.statusText);
                        filename = response.headers.get("content-disposition")?.match(/filename=\"(.*)\"/)?.pop();
                        return response.blob();
                    })
                    .then(blob => {
                        if (blob instanceof Blob) {
                            let url: string;
                            if (filename) {
                                const file = new File([blob], filename);
                                url = URL.createObjectURL(file);
                            }
                            else url = URL.createObjectURL(blob);
                            location.assign(url);
                        }
                        else throw new Error("blob is not a Blob for some reason.");
                    })
                    .catch(error => alerts.addDanger(String(error)))
                    .finally(() => {
                        if (this.downloadDataModal) {
                            preloader.hide(this.downloadDataModal);
                            Modal.getInstance(this.downloadDataModal)?.hide();
                        }
                        if (submitButton instanceof HTMLButtonElement || submitButton instanceof HTMLInputElement) {
                            submitButton.disabled = false;
                        }
                    })
            });
        }
    }

    initForm(form: HTMLFormElement, inputNames: string[]) {
        const submit = form.querySelector("input[type=submit], button[type=submit]");
        const inputs = <HTMLInputElement[]>inputNames
            .map(name => form.elements.namedItem(name))
            .filter(elem => elem instanceof HTMLInputElement);

        if (submit instanceof HTMLButtonElement || submit instanceof HTMLInputElement) {
            const inputEventHandler = () => {
                submit.disabled = inputs.some(e => e.value.length == 0);
            }

            inputs.forEach(input => {
                input.addEventListener("input", inputEventHandler);
                input.addEventListener("change", inputEventHandler);
                inputEventHandler();  // Initial run
            });
        }
    }
}

export default new UserManagementEntrypoint();
