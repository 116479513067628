import $ from "jquery";
import "select2";
import "select2/dist/js/i18n/sv";

export { autocompleteSearches, initAutocomplete };

const autocompleteSearches: Record<string, string> = {};


/**
 * Select fields rendered by edulms.widgets.AutocompleteSelect are
 * handled here. That widget is currently used in:
 *  - google.forms.AssignSchoolForm, for school
 *  - schools.forms.SchoolClassJoinForm, for school and class
 *  - users.forms.UserForm, for timezone
 *  - users.forms.TeacherCreationForm, for timezone
 */
function initAutocomplete(root: ParentNode) {
    root.querySelectorAll(".select2-autocomplete").forEach(elem => {
        $(elem)
            .select2()
            .on("select2:open", event => {
                const searchField = document.getElementsByClassName("select2-search__field").item(0);
                const value = autocompleteSearches[event.target.id];
                if (searchField instanceof HTMLInputElement) {
                    if (value) {
                        searchField.value = value;
                        searchField.dispatchEvent(new InputEvent("input"));
                    }
                    searchField.focus();
                }
            })
            .on("select2:closing", event => {
                const searchField = document.getElementsByClassName("select2-search__field").item(0);
                if (searchField instanceof HTMLInputElement && event.target.id) {
                    autocompleteSearches[event.target.id] = searchField.value;
                }
            });
    });

    // Do it again on window resize, because the field doesn't get resized
    // by itself. :-/
    window.addEventListener("resizeend", () => { initAutocomplete(root) });
}
